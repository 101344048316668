<template>
  <main class="main login" id="main" v-if="auth0Status() == 'notLogin'">
    <div class="col_logo">
      <h1><img class="img_logo" src="/img/logo-rectangle.png" alt="MC=Valuation" /></h1>
    </div>
    <div class="col_txt">
      <a class="link_next" @click="login">ログインページへ</a>
    </div>
  </main>

  <main class="main" id="main" v-else-if="auth0Status() == 'normalLogin'">
    <div class="col_info">
      <h2>お知らせ</h2>
      <dl class="lst_info scroll">
        <template v-for="(item, index) in news">
          <dt :key="index">{{ item.date }}</dt>
          <dd :key="`${index}-key`" v-html="item.news"></dd>
        </template>
      </dl>
    </div>

    <div class="col_side">
      <h2>コンテンツ選択</h2>
      <ul class="contents-erea">
        <li class="contents-line">
          <template v-for="n in 5">
            <a :href="'/analysis?name=' + menulist[n - 1].title" :key="n" v-if="menulist[n - 1].allow">
              <img :src="'/img/' + menulist[n - 1].name + '.svg'"
            /></a>
          </template>
        </li>
        <li class="contents-line">
          <template v-for="n in 2">
            <a :href="'/analysis?name=' + menulist[n + 4].title" :key="n + 4" v-if="menulist[n + 4].allow">
              <img :src="'/img/' + menulist[n + 4].name + '.svg'"
            /></a>
          </template>
        </li>
      </ul>
    </div>
  </main>

  <main v-else>
    <vue-loading type="bars" :size="{ width: '70px', height: '70px' }"></vue-loading>
  </main>
</template>

<script>
import { VueLoading } from 'vue-loading-template';
import _MENU_JSON from '../assets/data/menu.json';

export default {
  name: 'home',
  components: { VueLoading },
  data() {
    return {
      news: [],
      menulist: [],
      selected: false,
    };
  },
  async created() {
    if (this.auth0Status() == 'normalLogin') {
      let allow_dashboard = this.$auth.user['https://medicalbi.jp/allow_dashboard'];
      this.menulist = _MENU_JSON.map(function(item) {
        item['allow'] = allow_dashboard.includes(item.key);
        return item;
      });
    }
  },
  methods: {
    login() {
      this.$auth.loginWithRedirect();
    },
    auth0Status() {
      let ret;
      if (!this.$auth.loading && !this.$auth.isAuthenticated) {
        ret = 'notLogin';
      } else if (!this.$auth.loading && this.$auth.isAuthenticated) {
        ret = 'normalLogin';
      } else {
        ret = 'lodding';
      }
      return ret;
    },
    subMenuClose() {
      this.selected = false;
    },
  },
  mounted() {
    /*window.addEventListener('load', async () => {
      await this.$auth.getUser();
    });*/
    let self = this;
    fetch('/news.tsv').then(function(response) {
      let result = [];
      response.text().then(function(text) {
        let lines = text.split(/\r\n|\n/);
        let headers = ['date', 'news'];
        for (let i = 0; i < lines.length; i++) {
          let obj = {};
          let currentline = lines[i].split(/\t/);
          if (currentline[0]) {
            obj[headers[0]] = currentline[0];
            obj[headers[1]] = currentline[1];
            result.push(obj);
          }
        }
      });
      self.news = result;
    });

    document.addEventListener('click', this.subMenuClose);
  },
  updated() {
    if (this.$auth.isAuthenticated) {
      document.body.className = 'page_contents';
    } else {
      document.body.className = 'page_login';
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.subMenuClose);
  },
};
</script>

<style scoped lang="scss">
.vue-loading {
  color: var(--assort-color);
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

.login {
  .col_logo {
    margin-top: -40px;
    h2 {
      font-size: 3.2rem;
    }
    .img_logo {
      width: 410px;
    }
  }
  .col_txt {
    margin-top: -40px;
    width: 360px;
    .link_next {
      font-size: 2rem;
      padding: 20px 30px;
      color: #fff;
      background: var(--accent-color);
      border: 1px solid var(--accent-color);
      border-radius: 10px;
      cursor: pointer;
      &:hover {
        background: #fff;
        color: var(--accent-color);
      }
    }
  }
}

.col_info {
  background: var(--assort-light-color);
  //border: 1px solid var(--assort-color);
  margin: 0 auto 24px;
  overflow: hidden;
  width: 100%;
  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    align-items: center;
    color: #fff;
    background: var(--assort-color);
    margin: auto;
    padding: 2px 10px;
    text-align: center;
  }
  .lst_info {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    margin: 10px auto 10px;
    max-height: 85px;
    height: 85px;
    max-width: 1000px;
    overflow-y: auto;
    padding: 0 30px 0 0;
    dt {
      min-width: 120px;
    }
    dd {
      margin-left: 0;
      max-width: 1110px;
      padding-bottom: 8px;
      width: 86%;
      ::v-deep {
        a,
        a:link,
        a:visited,
        a:hover,
        a:active,
        a:focus {
          color: var(--assort-color);
        }
      }
    }
  }
  .scroll {
    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: var(--assort-color);
      border-radius: 6px;
    }
    &::-webkit-scrollbar-track {
      background: var(--memu-background-color);
      border-radius: 6px;
    }
  }
}

.col_side {
  position: relative;
  width: 100%;
  h2 {
    background: #f0f0f0;
    box-shadow: 5px 5px 5px rgba(23, 23, 23, 0.05) inset, -5px -5px 5px rgba(23, 23, 23, 0.05) inset;
    font-size: 2.6rem;
    font-weight: bold;
    align-items: center;
    margin: 10px auto 24px;
    padding: 2px 10px;
  }
  .contents-erea {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .contents-line {
      display: flex;
      gap: 20px;
      a {
        height: 162px;
        width: 162px;
      }
    }
  }
}
</style>
