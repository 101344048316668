<template>
  <footer class="footer"><small class="footer__copy">MC=Valuation Version 1.0.0</small></footer>
</template>
<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer {
  position: absolute;
  max-width: var(--max-width);
  width: 100%;
  padding: 8px 30px 30px;
  bottom: 0;
  text-align: center;
  left: 50%;
  transform: translate(-50%, 0);
  background: white;
  background: linear-gradient(0deg, #087fcf 10px, var(--assort-color) 50%, #087fcf 50%);
  color: #fff;
  margin: auto;
  .footer__copy {
    display: block;
    font-size: 1.3rem;
    line-height: 1.4;
    margin: auto;
  }
}
</style>
