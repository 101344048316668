<template>
  <header class="header unselectable">
    <div class="top-line" v-if="$auth.isAuthenticated">
      <div class="logo-wrapper">
        <a href="/"><img class="logo-image" src="/img/logo-wide.png" alt="MC=Valuation"/></a>
      </div>
      <div class="top-nav">
        <a class="help_link" target="_blank" href="https://dataanalysis.zendesk.com/hc/ja">ヘルプサイト</a>
        <p class="nav-clinic-name">{{ $auth.user['https://medicalbi.jp/clinic_name'] }} 様</p>
      </div>
    </div>
    <div class="top-menu" v-if="$auth.isAuthenticated">
      <p class="nav_ttl">Menu</p>
      <ul class="nav_menu">
        <li class="submenu nav_menu__home"><a class="link_anc" href="/">ホーム</a></li>
        <li class="submenu nav_menu__contents">
          <input type="checkbox" id="openContentsMenu" v-model="openContentsMenu" />
          <label class="link_anc" for="openContentsMenu" :style="{ background: openContentsMenu ? '#a6caf0' : '' }">コンテンツ</label>
          <transition name="fade">
            <ul class="nav_child" v-show="openContentsMenu">
              <li class="contents-line contents-mcv">
                <!--<template v-for="n in 5">
                  <a :href="'/analysis?name=' + menulist[n - 1].title" :key="n" v-if="menulist[n - 1].allow">{{ menulist[n - 1].title }}</a>
                  <div :key="n" v-else class="disabled-contents">{{ menulist[n - 1].title }}</div>
                </template>-->
                <template v-for="line in menulist">
                  <a :href="'/analysis?name=' + line.title" :key="line.title" v-if="line.allow">{{ line.title }}</a>
                  <div :key="line.title" v-else class="disabled-contents">{{ line.title }}</div>
                </template>
              </li>
              <!--<li class="contents-line">
                <template v-for="n in 2">
                  <a :href="'/analysis?name=' + menulist[n + 4].title" :key="n + 4" v-if="menulist[n + 4].allow">{{ menulist[n + 4].title }}</a>
                  <div :key="n" v-else class="disabled-contents">{{ menulist[n + 4].title }}</div>
                </template>
              </li>-->
            </ul>
          </transition>
        </li>
        <li class="submenu nav_menu__inq">
          <a class="link_anc" target="_blank" href="https://dataanalysis.zendesk.com/hc/ja/requests/new">お問い合わせ</a>
        </li>
        <li class="submenu nav_menu__logout" v-if="$auth.isAuthenticated" @click="openLogoutModal"><a class="link_anc">ログアウト</a></li>
      </ul>
    </div>
    <div class="logoutOverlay" :style="{ visibility: showLogoutModal ? 'visible' : 'hidden', opacity: showLogoutModal ? '1' : '0' }" @click="closeLogoutModal">
      <div class="content">
        <p>ログアウトしますか?</p>
        <div class="button-row">
          <div class="button" @click="closeLogoutModal">いいえ</div>
          <div class="button" @click="logout">ログアウト</div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import _MENU_JSON from '../assets/data/menu.json';

export default {
  name: 'Nav',
  data() {
    return {
      menulist: [],
      openContentsMenu: false,
      selected_user: false,
      showLogoutModal: false,
    };
  },
  created() {
    if (this.$auth.isAuthenticated) {
      let allow_dashboard = this.$auth.user['https://medicalbi.jp/allow_dashboard'];
      this.menulist = _MENU_JSON.map(function(item) {
        item['allow'] = allow_dashboard.includes(item.key);
        return item;
      });
    }
  },
  methods: {
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    subMenuClose() {
      this.openContentsMenu = false;
    },
    openLogoutModal: function() {
      this.showLogoutModal = true;
    },
    closeLogoutModal: function() {
      this.showLogoutModal = false;
    },
  },
  mounted() {
    document.addEventListener('click', this.subMenuClose);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.subMenuClose);
  },
};
</script>

<style scoped lang="scss">
.header {
  margin: 0 auto;
  width: var(--max-width);
  .top-line {
    display: flex;
    height: 80px;
    justify-content: flex-end;
    width: 100%;
    .logo-wrapper {
      align-self: center;
      width: 232px;
      .logo-image {
        display: block;
        width: 100%;
      }
    }
    .top-nav {
      background: linear-gradient(0deg, #087fcf 10px, var(--assort-color) 50%, #087fcf 50%);
      color: #fff;
      flex-grow: 1;
      padding: 7px 20px;
      position: relative;
      text-align: right;
      &::before {
        border-bottom: 80px solid #fff;
        border-left: 40px solid #fff;
        border-right: 40px solid transparent;
        border-top: 80px solid #fff;
        content: '';
        display: inline-block;
        height: 0;
        left: 0;
        position: absolute;
        top: -50%;
        width: 0;
      }
      .help_link {
        color: white;
        border-radius: 4px;
        padding: 4px 8px;
        cursor: pointer;
        &:hover {
          color: var(--text-color);
          background-color: #e8ffe2;
        }
        &::before {
          content: '?';
          display: inline-block;
          font-size: 14px;
          color: var(--text-color);
          background: #fff;
          height: 18px;
          width: 20px;
          text-align: center;
          padding-top: 2px;
          border-radius: 50%;
          margin-right: 6px;
        }
        &:hover::before {
          color: white;
          background: var(--assort-color);
        }
      }
      .nav-clinic-name {
        font-size: 2.8rem;
        font-weight: normal;
        margin: 8px 0 0;
        padding: 0;
      }
    }
  }

  .top-menu {
    align-items: center;
    display: flex;
    font-size: 1.8rem;
    justify-content: flex-end;
    .nav_ttl {
      color: #999;
      margin: 0 40px 0 0;
      padding: 0;
    }
    .nav_menu {
      display: flex;
      margin: 0;
      padding: 0;
      border-bottom: 1px solid #b3b3b3;
      .submenu {
        position: relative;
        .link_anc {
          align-items: center;
          display: flex;
          justify-content: center;
          height: 100%;
          width: 10em;
          padding: 5px 5px 2px;
          text-align: center;
          &::before {
            height: 32px;
            width: 34px;
            display: block;
            margin-right: 10px;
            padding: 4px 0 0;
          }
          &:hover {
            background: #a6caf0;
            &::before {
              background: #fff;
              border-radius: 50%;
            }
          }
        }
        &.nav_menu__home > .link_anc::before {
          content: url('/img/ico_gnav-home.svg');
        }
        &.nav_menu__user > .link_anc::before {
          content: url('/img/ico_gnav-user.svg');
        }
        &.nav_menu__contents > .link_anc::before {
          content: url('/img/ico_gnav-cont.svg');
        }
        &.nav_menu__inq > .link_anc::before {
          content: url('/img/ico_gnav-inq.svg');
        }
        &.nav_menu__logout > .link_anc::before {
          content: url('/img/ico_gnav-logout.svg');
        }
        &:not(:last-of-type) > .link_anc::after {
          content: '';
          position: absolute;
          vertical-align: middle;
          height: 80%;
          right: -2px;
          bottom: 4px;
          border-left: 1px solid #b3b3b3;
          width: 1px;
        }
        .nav_child {
          z-index: 100;
          position: absolute;
          display: flex;
          flex-direction: column;
          // width: 1096px;
          top: 43px;
          padding: 20px 8px 20px 12px;
          border-radius: 12px;
          text-align: left;
          color: var(--text-color);
          left: -0px;
          background-color: var(--memu-background-color);
          border: 2px solid var(--assort-light-color);
          flex-direction: row;
          flex-wrap: wrap;
          gap: 22px;
          .contents-line {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            gap: 22px 10px;
            list-style: none;
            margin: 0;
            padding: 0;
            a {
              margin: 0;
              width: 260px;
              // min-width: 260px;
              border-radius: 12px;
              transition: all 0.5s ease-out;
              line-height: 1.2;
              text-decoration: none;
              padding: 10px 14px;
              letter-spacing: 3px;
              font-size: 18px;
              color: var(--text-color);
              background-color: #f0f0f0;
              text-align: center;
              cursor: pointer;
              &:hover {
                background-color: var(--assort-color);
                color: white;
              }
            }
            .disabled-contents {
              margin: 0;
              min-width: 260px;
              border-radius: 12px;
              transition: all 0.5s ease-out;
              line-height: 1.2;
              text-decoration: none;
              padding: 10px 14px;
              letter-spacing: 3px;
              font-size: 18px;
              color: var(--text-color);
              background-color: #dbdbdb;
            }
          }
        }
      }
    }
  }

  .logoutOverlay {
    z-index: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: 0.25s linear;
    .content {
      z-index: 500;
      width: 380px;
      height: 170px;
      background: #fff;
      border-radius: 5px;
      position: relative;
      text-align: center;
      padding-top: 36px;
      p {
        display: flex;
        justify-content: center;
        &::before {
          content: url('/img/ico_gnav-logout.svg');
          margin-right: 18px;
          display: block;
          margin-top: -4px;
        }
      }
      .button-row {
        position: absolute;
        bottom: 0;
        flex-direction: row;
        display: flex;
        cursor: pointer;
        .button {
          width: 190px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          &:first-child {
            background-color: #f7f7f7;
            border-radius: 0 0 0 5px;
          }
          &:last-child {
            background-color: var(--assort-color);
            color: #fff;
            border-radius: 0 0 5px 0;
          }
        }
      }
    }
  }
}
</style>
