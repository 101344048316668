import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import { authGuard } from '../auth/authGuard';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: () => import('../views/Analysis.vue'),
    beforeEnter: authGuard
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    component: () => import('../views/ChangePassword.vue'),
    beforeEnter: authGuard
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

import { getInstance } from '../auth/index';
router.beforeEach((to, from, next) => {
  const authService = getInstance();

  const fn = () => {
    if (authService.isAuthenticated) {
      if (to.path != '/changepassword') {
        if (authService.user['https://medicalbi.jp/lastreset'] == '-1') {
          return next({ name: 'ChangePassword' });
        } else return next()
      } else return next()
    } else return next()
  };
  if (!authService.loading) {
    return fn();
  }
  authService.$watch('loading', loading => {
    if (loading === false) {
      return fn();
    }
  });
});

export default router
